import React, {Component} from 'react';
import { createaddtoAddress } from '../actions/address';
import Alert from '../layout/Alert';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Accountsidebar from '../components/Accountsidebar';
import { Link,NavLink } from 'react-router-dom'

const initialState =  {
        name: '',
        phone:'',
        email: '',
        address: '',
        city: '',
        zip: '',
        state:'' ,
        nameErrors:'',
        phoneErrors:'',
        emailErrors:'',
        addressErrors:'',
        cityErrors:'',
        zipErrors:'',
        stateErrors:'',
        status:1,
        addressbook:1
      };


  var zipRegex = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$/i;


 class NewAddress extends Component {
    

  state = initialState;


  handleInputChange = event => {
     const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value
    });
  };
   




  validateUKPhoneNumber(phoneNumber) {
    // Remove all spaces and special characters from the phone number
    phoneNumber = phoneNumber.replace(/\s|\(|\)/g, '');

    // Check if the phone number contains only digits after removing spaces and special characters
    if (/^\d+$/.test(phoneNumber)) {
        // Check if the length of the phone number is between 9 and 14 digits inclusively
        if (phoneNumber.length >= 9 && phoneNumber.length <= 14) {
            return true; // Valid UK phone number
        } else {
            return false; // Invalid UK phone number
        }
    } else {
        // Check if the phone number with spaces in specific positions
        var regex = /^(\+44\s?\d{4}\s?\d{6}|\(0\d{4}\)\s?\d{6}|\(0\d{3}\)\s?\d{3}\s?\d{4}|\(0\d{2}\)\s?\d{4}\s?\d{4})$/;
        return regex.test(phoneNumber);
    }
}


 validate = () => {
    let nameErrors = "";
    let phoneErrors = "";
    let emailErrors = "";
    let addressErrors = "";
    let cityErrors = "";
    let zipErrors = "";
    let stateErrors = "";


   if (!this.state.name) {
       nameErrors = "Name cannot be blank";
    }
    
   if (!this.state.phone) {
       phoneErrors = "Phone Number cannot be blank.";
    }
    else if (!this.validateUKPhoneNumber(this.state.phone) && this.state.phone !== "") {
      phoneErrors = "Please enter valid Phone Number";
   }



   if (!this.state.address) {
       addressErrors = "Address cannot be blank";
    }
   if (!this.state.city) {
       cityErrors = "City cannot be blank";
    }
    if (!this.state.zip) {
     
       zipErrors = "Postcode cannot be blank";
    }
    else if (!zipRegex.test(this.state.zip)) {
      zipErrors = "Please enter valid Postcode";
    } 
    if (!this.state.state) {
       stateErrors = "State cannot be blank";
    }
   

   if (nameErrors || phoneErrors  || addressErrors || cityErrors || zipErrors || stateErrors) {
      this.setState({nameErrors,phoneErrors,addressErrors,cityErrors,zipErrors,stateErrors });
      return false;
    }
return true;
  };

handleSubmit = e => {
    e.preventDefault();
   const isValid = this.validate();
    if (isValid) {
      this.props.onAddAddress(this.state);

      console.log(this.state);
      
      this.setState(initialState);

  }
};
componentDidMount() {
    console.log(this.props);
} 



    render(){


  return (

 <div id="main-wrapper">
        <Header/>
          <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
              <div className="container">
                  <div className="row">
                      <div className="col">
                          <div className="page-banner text-center">
                              <h1><a href="/dashboard">Dashboard</a></h1>
                              <ul className="page-breadcrumb">
                                  <li><Link exact to="/" >Home</Link></li>
                                  <li>Add Address</li>
                              </ul>
                          </div>                        
                      </div>
                  </div>
              </div>
          </div>     
        <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row">  
                            <Accountsidebar/>   
                             <div className="col-lg-9 col-12">
                                <div className="tab-content" id="myaccountContent">
                                  <div className="tab-pane fade show active" id="address-edit" role="tabpanel">
                                    <div className="myaccount-contentab" >
                                      <h3>Address Book</h3>
                                      <div style={{'textAlign':'right'}}>
                                         <NavLink exact to="/addressbook" > <button className="place-order btn btn-lg btn-round">Back to  Address Book</button></NavLink>
                                      </div>
                                  
                                <form onSubmit={ this.handleSubmit }>
                                <div className="col-md-12 col-lg-8" style={{'paddingLeft':'0px'}}><Alert/> </div>
                                   
                    <div className="tt-login-form">
                       <div className="row justify-content-left">
                        <div className="col-md-12 col-lg-8">         
                            <div className="form-default">
                                  <div id="resp" className="col-md-6 col-md-offset-3">
                                  </div>
                                  <div style={{'clear':'both','height':'2px'}}></div>
                                    <div className="form-group">
                          <label>Full Name*</label>
                          <input type="text" placeholder="Full Name" name="name" onChange={ this.handleInputChange } className="form-control" value={ this.state.name }/>
                          <div className='warning-msg'>{this.state.nameErrors}</div>

                      </div>
                
                        <div className="form-group">
                          <label>Phone Number*</label>
                          <input type="text" placeholder="Phone number" name="phone" maxlength="20"  onChange={ this.handleInputChange } className="form-control" value={ this.state.phone }/>
                          <div className='warning-msg'>{this.state.phoneErrors}</div>

                      </div>
                   <div className="form-group">
                          <label>Address*</label>
                          <input type="text" placeholder="Address line 1" name="address" onChange={ this.handleInputChange } className="form-control" value={ this.state.address }/>                                            
                          <div className='warning-msg'>{this.state.addressErrors}</div>

                      </div>
                      <div className="form-group">
                          <label>Town/City*</label>
                          <input type="text" placeholder="Town/City" name="city" onChange={ this.handleInputChange } className="form-control" value={ this.state.city}/>
                          <div className='warning-msg'>{this.state.cityErrors}</div>
                      </div>
                      <div className="form-group ">
                          <label>State*</label>

                          <input
                            className="form-control"
                              type="text"
                              id="state"
                              name="state"
                              maxlength="15"
                              minlength="3"
                              placeholder="State"
                              onChange={this.handleInputChange}
                              value={this.state.state}
                              autoComplete="off"
                            />

<div className='warning-msg'>{this.state.stateErrors}</div>

                      </div>

                        <div className="form-group">
                          <label>PostCode*</label>
                          <input type="text" name="zip"  maxlength="16" placeholder="PostCode"onChange={ this.handleInputChange } className="form-control" value={ this.state.zip }/>
                          <div className='warning-msg'>{this.state.zipErrors}</div>

                      </div>
                         <div className="form-group">
                      <input name="status"  className="form-control" type="hidden" onChange={ this.handleInputChange } value={ this.state.status }/>                                      
                        </div>  
                      <div class="col-12 mb-30">
                      
                       <button class="place-order btn btn-lg btn-round">Add Address</button>
                      </div>
                    </div>
                  </div>
               </div>
            </div>
          </form>
                                     </div>
                                  </div>
                              </div>
                          </div>


    
                         </div>
                    </div> 
                </div>           
            </div>
        </div>
    <Footer/>
</div>


    )
}
}


const mapDispatchToProps = dispatch => {
  return {
    onAddAddress: post => {

      dispatch(createaddtoAddress(post));
       
    }

  };
};

const mapStateToProps = state => {
  return {
    address: state.address
  };
};

export default connect(
  null,
  mapDispatchToProps
)(NewAddress);




