import React, {Component} from 'react';

export default class Termcondition extends Component {
render(){
return (

<div className="shop-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
<div className="container">

<div class="row"><div class="col"><div class="section-title mb-20 mb-xs-20 text-center">
<h2 className="oursolutionleafbg" style={{'fontWeight':'600','color':'#db9c28'}}>TERMS AND CONDITIONS</h2>

</div></div></div>


<p class="prod-title ptag">
<strong>Introduction</strong>
</p>


<div className="row">
<div className="col-12">
<p class="prod-title ptag">
	Welcome to The Organic Forest UK website, found at https://www.theorganicforest.co.uk (hereafter referred to as the "Website"). These Terms and Conditions govern your use of the Website and its associated services (collectively, the "Service"), which is managed by The Organic Forest UK, a division of TORFO Wellness Private Limited.
</p>


<p class="prod-title ptag">
<strong>Acceptance of Terms</strong>
</p>
<p class="prod-title ptag">
By accessing or using the Service, you confirm your agreement to be bound by these Terms and Conditions. If you disagree with these terms, please refrain from using the Service.
</p>


<p class="prod-title ptag">
	<strong>Communications</strong>
</p>

<p class="prod-title ptag">
Upon signing up for an account on our Website, you consent to receive periodic newsletters and promotional materials. You have the option to opt-out of such communications by following the unsubscribe instructions provided in our emails.
</p>

<p class="prod-title ptag">
<strong>Purchases</strong>
</p>

<p class="prod-title ptag">
When making a purchase through the Service, you may be required to provide certain information, such as payment card details and shipping information. You confirm that all information you provide is accurate and that you have the right to use the submitted payment method.</p>


<p class="prod-title ptag">
We use third-party services for payment processing and by providing your information, you consent to our sharing this information with these third-party services as detailed in our Privacy Policy.</p>

<p class="prod-title ptag">
We reserve the right to cancel or refuse orders for reasons such as inaccuracies in product information or unavailability, among others.</p>


<p class="prod-title ptag">
	<strong>Availability and Accuracy</strong>
</p>

<p class="prod-title ptag">
The Website is regularly updated, and information might sometimes be delayed in update or include errors. We reserve the right to change or correct any information and to cancel orders due to inaccurate information at any time.</p>



<p class="prod-title ptag">
<strong>Content</strong>
</p>

<p class="prod-title ptag">
By utilizing our Service, you may have the chance to post, link, store, share, or otherwise make available certain information, text, graphics, videos or other materials ("Content"). As the content provider, you are liable for ensuring the legality, reliability, and appropriateness of your Content.</p>

<p class="prod-title ptag">
When posting Content via our Service, you affirm and warrant that: (i) the Content either belongs to you, or you have the requisite permissions to use and grant us the rights and license as outlined in these Terms; (ii) posting your Content through the Service doesn't infringe on privacy rights, publicity rights, copyrights, contract rights, or any other rights of any person or entity. We maintain the right to terminate any account found to be violating copyright rules.</p>

<p class="prod-title ptag">
Although you retain ownership over Content you submit, post, or display on our Service, caveat: by posting, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. This license permits us to make your Content accessible to other users of the Service, under these same Terms.</p>

<p class="prod-title ptag">
Our team at TORFO Wellness Private Limited reserves the right, although not obligated, to monitor and modify Content provided by users.
</p>


<p class="prod-title ptag">
Moreover, all Content found on or through this Service is owned by either The Organic Forest or used with expressed permissions. Any unauthorised use or distribution of said Content is strictly prohibited.
</p>


<p class="prod-title ptag">
<strong>Accounts</strong></p>


<p class="prod-title ptag">
By creating an account on our Website, you affirm that you are over 18 years of age and that the information provided is current and complete. You are responsible for safeguarding your account and notifying us if there are any unauthorized uses of your account.</p>

<p class="prod-title ptag">
You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorisation. You may not use as a username any name that is offensive, vulgar or obscene.
</p>


<p class="prod-title ptag">
We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.
</p>


<p class="prod-title ptag"><strong>Copyright Policy</strong></p>

<p class="prod-title ptag">
At The Organic Forest, we respect the intellectual property rights of others and expect the same from the users of our Service. We will respond to notices of alleged copyright infringement that comply with UK copyright law and the general principles of intellectual property protection.
</p>

<p class="prod-title ptag">
If you believe that your work has been copied and posted on the Service in a way that constitutes copyright infringement, please provide us with the following information:
</p>

<p class="prod-title ptag">
	<ul>
		<li>A physical or electronic signature of the person authorised to act on behalf of the owner of the copyright interest; </li>
		<li>A description of the copyrighted work that you claim has been infringed; </li>
		<li>A description of where the material that you claim is infringing is located on the Service (i.e., the specific URLs); </li>
		<li>Your address, telephone number, and email address; </li>
		<li>A statement by you that you have a good faith belief that the disputed use is not authorised by the copyright owner, its agent, or the law; </li>
		<li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorised to act on the copyright owner's behalf. </li>
	</ul>
</p>

<p class="prod-title ptag">
Please send your notice of copyright infringement to us via email at care@theorganicforest.co.uk with the subject line: "Copyright Infringement Notice".
</p>

<p class="prod-title ptag">
Our policy is to respond promptly to claims of intellectual property misuse and to take appropriate actions under the UK Copyright, Designs, and Patents Act 1988 and other applicable intellectual property laws.
</p>
<p class="prod-title ptag">
Please note, making false claims of copyright infringement can result in personal liability and legal consequences. Therefore, if you are unsure whether the material you are reporting is in fact infringing, you may wish to contact an attorney before submitting a notification to us.
</p>

<p class="prod-title ptag">
<strong>Intellectual Property</strong>
</p>

<p class="prod-title ptag">
All content on the Website is the intellectual property of The Organic Forest UK or its licensors. Usage of any Website content for commercial purposes requires express written permission from us.
</p>


<p class="prod-title ptag">
<strong>Third-Party Links</strong>
</p>

<p class="prod-title ptag">
	The Website may contain links to third-party websites. Engaging with these links is done at your own risk; The Organic Forest UK does not endorse or assume any responsibility for these external websites.
</p>


<p class="prod-title ptag">
<strong>Termination</strong>
</p>

<p class="prod-title ptag">
We may terminate or suspend your account and access to the Service at our discretion, without prior notice.
</p>


<p class="prod-title ptag">
<strong>Indemnification</strong>
</p>

<p class="prod-title ptag">
You agree to defend and indemnify The Organic Forest UK and its affiliates against all liabilities, damages, and costs arising from your use of the Service, breach of these Terms, or posted Content.
</p>

<p class="prod-title ptag">
<strong>Limitation of Liability</strong>
</p>

<p class="prod-title ptag">
The Organic Forest UK's liability is restricted as per applicable law, and we are not liable for any indirect or consequential damages arising from your use of the Service.</p>

<p class="prod-title ptag">
<strong>Disclaimer</strong>
</p>

<p class="prod-title ptag">
We may modify these Terms at any time, and continued use of the Website following such changes indicates your acceptance of the new terms.</p>

<p class="prod-title ptag">
<strong>Governing Law</strong>
</p>

<p class="prod-title ptag">
These Terms shall be governed by the laws of the United Kingdom.</p>


<p class="prod-title ptag">
<strong>Shipping</strong>
</p>

<p class="prod-title ptag">
The Organic Forest UK ships within the UK. Shipping times range between 2-10 working days, and shipping fees may apply depending on the total value of your order.</p>


<p class="prod-title ptag">
<strong>Returns and Exchanges</strong>
</p>

<p class="prod-title ptag">
We accept returns and exchanges under specific conditions, including undamaged and intact product packaging. Please request a return or exchange within 48 hours of receipt.
</p>


<p class="prod-title ptag">
<strong> Contact Us</strong>
</p>

<p class="prod-title ptag">
For questions regarding the Terms of Service, contact us at care@theorganicforest.co.uk.
</p>



</div>
</div>
</div>
</div>

)
}
}