import React, { Component } from "react";
import { connect } from "react-redux";
import Checkoutcart from "../components/Checkoutcart";
import { withRouter, Redirect } from "react-router-dom";
import { createaddtoAddress } from "../actions/address";
import { getCookieValue, setCookie } from "../utils/cookie";

class Shipping extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  state = {
    // name: this.props.accountinfo ? this.props.accountinfo.name: null,
    // lastname :this.props.accountinfo ? this.props.accountinfo.lastname : null,
    // phone: this.props.accountinfo ? this.props.accountinfo.phone: null,
    // gender :this.props.accountinfo ? this.props.accountinfo.gender : null,

    name: "",
    phone: "",
    address: "",
    city: "",
    zip: "",
    state: "",
    nameErrors: "",
    phoneErrors: "",
    addressErrors: "",
    cityErrors: "",
    zipErrors: "",
    stateErrors: "",
    status: 1,
    show_address: false,
    bill_id: true,
    ship_id: true,
    addressbook: 0,
    uniqueid: getCookieValue("cartid"),
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });

    if (target.name != "bill_id") {
      this.setState({ show_address: true });
    }
  }



  validateUKPhoneNumber(phoneNumber) {
    // Remove all spaces and special characters from the phone number
    phoneNumber = phoneNumber.replace(/\s|\(|\)/g, '');

    // Check if the phone number contains only digits after removing spaces and special characters
    if (/^\d+$/.test(phoneNumber)) {
        // Check if the length of the phone number is between 9 and 14 digits inclusively
        if (phoneNumber.length >= 9 && phoneNumber.length <= 14) {
            return true; // Valid UK phone number
        } else {
            return false; // Invalid UK phone number
        }
    } else {
        // Check if the phone number with spaces in specific positions
        var regex = /^(\+44\s?\d{4}\s?\d{6}|\(0\d{4}\)\s?\d{6}|\(0\d{3}\)\s?\d{3}\s?\d{4}|\(0\d{2}\)\s?\d{4}\s?\d{4})$/;
        return regex.test(phoneNumber);
    }
}
  


  validate = () => {
    let nameErrors = "";
    let phoneErrors = "";
    let emailErrors = "";
    let addressErrors = "";
    let cityErrors = "";
    let zipErrors = "";
    let stateErrors = "";

    var zipRegex = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$/i;



    if (!this.state.name) {
      nameErrors = "Name cannot be blank";
    }
    if (!this.state.phone) {
      phoneErrors = "Phone Number cannot be blank";
    } else if (!this.validateUKPhoneNumber(this.state.phone) && this.state.phone !== "") {
       phoneErrors = "Please enter valid Phone Number";
    }

    if (!this.state.address) {
      addressErrors = "Address cannot be blank";
    }
    if (!this.state.city) {
      cityErrors = "City cannot be blank";
    }
    if (!this.state.zip) {
      zipErrors = "Postcode cannot be blank";
    } else if (!zipRegex.test(this.state.zip)) {
      zipErrors = "Please enter valid Postcode";
    }
    if (!this.state.state) {
      stateErrors = "State cannot be blank";
    }

    if (
      nameErrors ||
      phoneErrors ||
      addressErrors ||
      cityErrors ||
      zipErrors ||
      stateErrors
    ) {
      this.setState({
        nameErrors,
        phoneErrors,
        addressErrors,
        cityErrors,
        zipErrors,
        stateErrors,
      });
      return false;
    }
    return true;
  };

  handleChange = (e) => {
    let addressid = e.target.value;

    this.setState({
      name: this.props.address ? this.props.address[addressid].name : "",
      phone: this.props.address ? this.props.address[addressid].phone : "",
      address: this.props.address ? this.props.address[addressid].address : "",
      zip: this.props.address ? this.props.address[addressid].zip : "",
      state: this.props.address ? this.props.address[addressid].state : "",
      city: this.props.address ? this.props.address[addressid].city : "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.props.onAddAddress(this.state);
      this.props.history.push({ pathname: "/billing" });
      this.props.history.push({
        pathname: "/billing",
        state: { detail: this.state },
      });
    }
  };

  componentDidMount() {
    if (this.props.address != "" && this.props.cartproduct != "") {
      let cartaddressid = this.props.cartproduct
        ? this.props.cartproduct[0].ship_id
        : 0;

      if (cartaddressid != null) {
        var selectedaddress = this.props.address.filter((post) => {
          return post.id == cartaddressid;
        });

        var selectedaddress =
          selectedaddress && selectedaddress.length
            ? selectedaddress[0]
            : this.props.address[0];

        this.setState({
          name: selectedaddress.name,
          phone: selectedaddress.phone,
          address: selectedaddress.address,
          city: selectedaddress.city,
          zip: selectedaddress.zip,
          state: selectedaddress.state,
        });
      } else {
        this.setState({
          name: this.props.address[0].name,
          phone: this.props.address[0].phone,
          address: this.props.address[0].address,
          city: this.props.address[0].city,
          zip: this.props.address[0].zip,
          state: this.props.address[0].state,
        });
      }
    } else {
      this.setState({ show_address: true });
    }
  }

  render() {
    const { cartproduct, address } = this.props;

    if (cartproduct == "") {
      return <Redirect to="/cart" />;
    }

    let selcartid = this.props.cartproduct
      ? this.props.cartproduct[0].ship_id
      : 0;

    return (
      <div>
        <div
          className="page-banner-section section bg-gray"
          style={{ padding: "10px 0 20px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Checkout</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li>Shipping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="checkout-progress-indicator">
          <div class="step step-1 active">
            <span class="step-content">
              <span class="step-number" style={{ marginRight: "5px" }}>
                <img src="/images/shipping.png" />
              </span>
              Shipping
            </span>
          </div>
          <div class="step inactive">
            <span class="step-content">
              <span class="step-number" style={{ marginRight: "5px" }}>
                <img src="/images/billing.png" />
              </span>
              Billing &amp; Payment
            </span>
          </div>
          <div class="step inactive">
            <span class="step-content">
              <span class="step-number" style={{ marginRight: "5px" }}>
                <img src="/images/confirm.png" />
              </span>
              Order Confirmation
            </span>
          </div>
        </div>

        <div class="checkout-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="row row-40">
                  <div class="col-lg-7">
                    <form
                      onSubmit={this.handleSubmit}
                      className="checkout-form"
                    >
                      <div id="shipping-form1">
                        <h4 class="checkout-title">Shipping Address</h4>

                        {this.props.address != "" && this.props.address && (
                          <div class="row">
                            <div class="col-12 mb-5">
                              <select
                                class="nice-select selectalignment"
                                onChange={this.handleChange}
                              >
                                {this.props.address.map((post, index) => {
                                  return (
                                    <option
                                      key={post.id}
                                      value={index}
                                      selected={selcartid == post.id}
                                    >
                                      ({post.city}) {post.name} {post.address}{" "}
                                      {post.zip}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}

                        <div class="row">
                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="name_id"
                              name="name"
                              value=""
                              placeholder="Full Name"
                              onChange={this.handleInputChange}
                              value={this.state.name}
                              autoComplete="off"
                            />
                            <div className='warning-msg'>
                              {this.state.nameErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="phone_id"
                              maxlength="20"
                              name="phone"
                              placeholder="Phone Number"
                              onChange={this.handleInputChange}
                              value={this.state.phone}
                              autoComplete="off"
                            />
                            <div className='warning-msg'>
                              {this.state.phoneErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="address_id"
                              name="address"
                              placeholder="Address"
                              onChange={this.handleInputChange}
                              value={this.state.address}
                              autoComplete="off"
                            />
                            <div className='warning-msg'>
                              {this.state.addressErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="city_id"
                              name="city"
                              value=""
                              placeholder="City"
                              onChange={this.handleInputChange}
                              value={this.state.city}
                              autoComplete="off"
                            />
                          </div>

                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="zip_id"
                              name="zip"
                              maxlength="16"
                              title="Enter 6 digit Postcode"
                              placeholder="Postcode"
                              onChange={this.handleInputChange}
                              value={this.state.zip}
                              autoComplete="off"
                            />
                            <div className='warning-msg'>
                              {this.state.zipErrors}
                            </div>
                          </div>
                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="state"
                              name="state"
                              maxlength="15"
                              minlength="3"
                              placeholder="State"
                              onChange={this.handleInputChange}
                              value={this.state.state}
                              autoComplete="off"
                            />

                            <div className='warning-msg'>
                              {this.state.stateErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            {this.state.show_address && (
                              <div class="single-method1">
                                <input
                                  type="checkbox"
                                  id="add_address"
                                  name="status"
                                  checked={this.state.status}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  for="status"
                                  style={{
                                    color: "#000000",
                                    display: "inline-block",
                                    fontWeight: "400",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Add to Address Book
                                </label>
                              </div>
                            )}

                            <div class="single-method1">
                              <input
                                type="checkbox"
                                id="bill_id"
                                name="bill_id"
                                checked={this.state.bill_id}
                                onChange={this.handleInputChange}
                              />
                              <label
                                for="bill_id"
                                style={{
                                  color: "#000000",
                                  display: "inline-block",
                                  fontWeight: "400",
                                  marginLeft: "5px",
                                }}
                              >
                                Use this address for Billing
                              </label>
                            </div>

                            <button
                              class="descount-sticker_btn_detail descount-sticker_btn"
                              style={{ marginTop: "10px" }}
                            >
                              Proceed to Billing
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <Checkoutcart cartproduct={cartproduct} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAddress: (post) => {
      dispatch(createaddtoAddress(post));
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Shipping));
