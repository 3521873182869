import React from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import Addtocart from '../components/Addtocart';
import {Currency} from '../utils/currency';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

export default ({post}) => {

const shopdata={id:post.id,feature_image:post.feature_image,quantity:1,price:post.price,title:post.title,shipping:post.shipping,sizes:post.sizes,slug:post.slug};

let eo_co_class;

if(post.id>92)
    {
        eo_co_class='eo-co-product';

    }
    else
    {
        eo_co_class='';

    }




return (

  
<div class="col-lg-3 col-md-6 col-6">
<div class="single-product mb-30 ">
<div class={'product-img shop-product-list '+eo_co_class }>

<Link to ={'/product/' +post.slug} className="prodimgsrc" >
{/*<img src="/images/bbg-product.png" alt="Blank_Images" alt="Blank_Images" style={{'position':'absolute'}} />*/}
<LazyLoadImage src={IMG_BASE_URL+'products/'+post.feature_image} effect="blur" alt="Product" />

</Link>
</div>
<div class="product-content">

{/*<div className="single-product-reviews">
<i className="fa fa-star unselctleaf"></i>
<i className="fa fa-star unselctleaf"></i>
<i className="fa fa-star unselctleaf"></i>
<i className="fa fa-star unselctleaf"></i>
<i className="fa fa-star unselctleaf"></i> &nbsp; <span style={{'color':'#193a43', 'fontSize':'13px'}}>2947 reviews</span></div>*/}


<h3><Link to ={'/product/' +post.slug} >{post.title}</Link></h3>
<h4 class="price pricecont">
<div class="older">{Currency}{post.current_price} {/*<span>{Currency} {post.mrp_price}</span>*/}</div>



<Addtocart produtdata={shopdata} />


</h4>
</div>
</div>

</div>

)
    }














