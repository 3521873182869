import React, {Component} from 'react';
import Oursolution from '../components/Oursolution';
import {fetchAllDealsProducts } from '../actions/dealsproduct';
import { connect } from 'react-redux';
import Products from '../components/Products';
import Carousel from 'react-bootstrap/Carousel'

class FeaturedContainer extends Component {

componentDidMount() {
window.scrollTo(0, 0);
this.props.dispatch(fetchAllDealsProducts());
}


render(){

const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
      

const {dealproducts}  = this.props;





return (


<div id="main-wrapper">

<div className="product-section section pt-md-65 pt-sm-60 pt-xs-45 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 desktop-view" style={{'marginBottom':'40px'}}>
<div className="container">
<div className="row">
<div className="col-12">
<div className=" mb-2 pt-lg-40 mb-xs-20 text-center largeview">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#193a43'}}>Best Sellers</h2>
</div>
</div>
</div>

<div className="row pt-lg-40">
{dealproducts.map(post => {
return (
<Products post={post}  key={ post.id }/>
);
})}
</div>
</div>
</div>


<div className="product-section section pt-md-65 pt-sm-60   pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 mobile-view mobile-tablet" >
<div className="container">
<div className="row">
<div className="col">
<div className=" mb-2 mb-xs-20 text-center">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>Best Seller</h2>
</div>
</div>
</div>

<div className="row">
{dealproducts.slice(0,4).map(post => {
return (
<Products post={post}  key={ post.id }/>
);
})}
</div>
</div>
</div>


</div>



)
}
}





const mapStateToProps = (state) => ({
   dealproducts:state.dealproducts,
    
    });




export default connect(
mapStateToProps,
null
)(FeaturedContainer);