import React, {Component} from 'react';

export default class Privacypolicy extends Component {
render(){
return (

<div className="shop-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
<div className="container">

<div class="row"><div class="col"><div class="section-title mb-20 mb-xs-20 text-center">
<h2 className="oursolutionleafbg" style={{'fontWeight':'600','color':'#db9c28'}}>PRIVACY POLICY</h2>

</div></div></div>
<div className="row">
<div className="col-12">

<p class="prod-title ptag">
This Privacy Policy is created in accordance with the UK Data Protection Act 2018 (DPA 2018) and the UK General Data Protection Regulation (UK GDPR). This digital document does not require a physical or digital signature. It applies to all personal data collected by The Organic Forest, which is operated by TORFO Wellness Private Limited (referred to as "we", "us", or "our"), accessible at www.theorganicforest.co.uk ("our website").
</p>

<p class="prod-title ptag">
We are committed to safeguarding the privacy and confidentiality of your information while using our website.
</p>

<p class="prod-title ptag">
This Privacy Policy explains what information we collect, why we collect it, how we use it, the controls you have over your personal data and the procedures we have in place to protect it. It applies to personal data collected when you use our website, when you interact with us through social media, email, or phone, or when you participate in our competitions or events. It also applies to personal data collected during the provision of our products and services.
</p>       

<p class="prod-title ptag">
<strong>1. Information We Collect and How We Use It</strong>
</p>

<p class="prod-title ptag">
	We collect personal data you give us voluntarily (such as your name, email, address, phone number, payment details) and data collected automatically (such as IP address, device ID, geographic location, referral-source, browsing patterns). We use this information for purposes including fulfilling orders, managing accounts, conducting market research and improving website usability, providing customer service, and for personalized marketing purposes.
</p>

<p class="prod-title ptag">
	When you make use of our website, you agree to our collection, storing, processing, and sharing of such personal and non-personal data, in line with this Policy.
</p>

<p class="prod-title ptag">
	You have the right to decline providing us with personal data, but it may hinder us from providing certain services or features of the Website.
</p>

<p class="prod-title ptag">
	<strong>2. How We Use and Share Your Information</strong>
</p>

<p class="prod-title ptag">
The use of your information is essential for us to check user eligibility, deliver and improve our products/services, manage your account, conduct research, provide you with promotional offers and tailored content, and resolve any disputes or issues you might have. We only share personal data with partners, service providers, or affiliates as described herein, to fulfil service and product requests or as required by law.
</p>

<p class="prod-title ptag">
	<strong>3. Cookies and Third Party Websites</strong>
</p>
<p class="prod-title ptag">
	We use cookies on our website to improve user experience. Third party websites linked from our website have their own privacy policies. We recommend you review these policies before providing them with personal data.
</p>

<p class="prod-title ptag">
<strong>4. Links to Third Party Websites </strong></p>

<p class="prod-title ptag">
The Website may, from time to time, contain links to as well as from third party websites, including our partner websites, advertisers or affiliates. If you follow such link to any of these websites, please note that these websites have their own privacy policies and terms of use and that we do not accept any liability or responsibility for their said policies. Please check these third party policies before you provide any information to them.
</p>

<p class="prod-title ptag">
	<strong>5. Securing Your Personal Data </strong>
</p>

<p class="prod-title ptag">
We strive to protect your personal data and to prevent unauthorised access to it through the use of secure technology and internal procedures. However, we cannot guarantee the security of data transmitted over the internet.
</p>

<p class="prod-title ptag">
<strong>6. Changes to This Policy</strong></p>
<p class="prod-title ptag">
We may update our Privacy Policy, so please review it regularly. We will notify you of any significant changes by contacting you directly where reasonably possible for us to do so and by placing an update notice on our website.
</p>

<p class="prod-title ptag">
<strong>7. Contacting Us </strong>
</p>

<p class="prod-title ptag"  style={{'textAlign':'left'}}>
We welcome any questions, comments, or complaints you may have regarding this Privacy Policy. Please contact us at care@theorganicforest.co.uk.
</p>

<p class="prod-title ptag"  style={{'textAlign':'left'}}>
	We recommend understanding this privacy policy clearly as by using our website, services or products, you acknowledge you accept this Privacy Policy. If you do not agree, please refrain from using our website or services.
</p>

<p></p>

</div>
</div>
</div>
</div>


)
}
}